import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import layoutBottom from "@/components/layoutBottom";
import mitt from "mitt";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import "@/style/reset.css";
import "@/style/normalize.css";
import "@/utils/flexible.js";
import "element-plus/dist/index.css";
import "@/style/resetElement.less";
import "@/style/iconfont.css";
import { matrix } from "@/api/matrix";
import { directive } from "@/utils/directive";
import zhCn from "element-plus/es/locale/lang/zh-cn";

const emitter = mitt();
const pinia = createPinia();
pinia.use(piniaPersist);
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(pinia);
app.use(ElementPlus, {
  locale: zhCn,
});
directive(app);
import { useMainStore } from "@/store";
const store = useMainStore();
// 获取联盟列表
matrix().then((res) => {
  if (res.code === 1) {
    router.addRoute("main", {
      path: "union",
      name: "union",
      component: () => import("@/views/union/index/index.vue"),
      redirect: "/union/union" + res.data[0].id,
      children: [],
      meta: {
        title: "晋开红链",
      },
    });
    const unionMenus = [];
    res.data.map((v) => {
      const route = {
        path: "union" + v.id,
        meta: {
          id: v.id,
          //   images: v.images,
          title: v.name,
          showLeftMenu: true,
          hideSearch: true,
          total_company: v.total_company,
          total_integral: v.total_integral,
          image: v.images,
        },
        children: [
          {
            path: "",
            name: "union" + v.id,
            meta: {
              title: v.name,
              hideMenu: true, //是否显示在左边导航
            },
            component: () => import("@/views/union/info/index.vue"),
          },
          {
            path: "appeal",
            name: "appeal" + v.id,
            meta: {
              title: "诉求直达",
              hideMenu: true, //是否显示在左边导航
            },
            component: () => import("@/views/union/appeal/index.vue"),
          },
          {
            path: "footprint/:id",
            name: "footprint" + v.id,
            meta: {
              title: "我们的足迹",
              hideMenu: true, //是否显示在左边导航
              showLeftMenu: false,
              noBreadcrumb: true,
            },
            component: () => import("@/views/union/footprint/index.vue"),
          },
          {
            path: "activity/:id",
            name: "activity" + v.id,
            meta: {
              title: "活动通知",
              hideMenu: true, //是否显示在左边导航
              //   noBreadcrumb: true,
            },
            component: () => import("@/views/union/activity/index.vue"),
          },
          {
            path: "companyDetail",
            meta: {
              title: "公司详情",
              hideMenu: true, //是否显示在左边导航
            },
            beforeEnter: (to, from, next) => {
              to.meta.title = to.query.name;
              to.matched[3].meta.title = to.query.name;
              to.matched[3].meta.query = to.query;
              to.matched[3].meta.params = to.params;
              next();
            },
            children: [
              {
                path: "",
                name: "companyDetail" + v.id,
                meta: {
                  //   title: "xxx公司",
                  hideMenu: true, //是否显示在左边导航
                  showLeftMenu: false,
                },
                component: () =>
                  import("@/views/union/companyDetail/index.vue"),
              },
              {
                path: "supply/:id",
                name: "supply" + v.id,
                meta: {
                  title: "供应详情",
                  hideMenu: true, //是否显示在左边导航
                  showLeftMenu: false,
                  noBreadcrumb: true,
                  hideSearch: true,
                },
                component: () => import("@/views/union/supply/index.vue"),
              },
              {
                path: "demand/:id",
                name: "demand" + v.id,
                meta: {
                  title: "需求详情",
                  hideMenu: true, //是否显示在左边导航
                  showLeftMenu: false,
                  noBreadcrumb: true,
                  hideSearch: true,
                },
                component: () => import("@/views/union/demand/index.vue"),
              },
            ],
          },
        ],
      };
      router.addRoute("union", route);
      unionMenus.push(route);
    });
    store.setUnionList(unionMenus);
    app
      .use(router)
      .use(ElementPlus)
      .component("layoutBottom", layoutBottom)
      .provide("emitter", emitter)
      .mount("#app");
  }
});
